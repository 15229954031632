//var dirPath = window.location.pathname.split('/')[1];
var hostPath = window.location.protocol + "//" + window.location.host + window.location.pathname.split('/')[0];
var virtualPath = hostPath + window.location.pathname;
//var virtualPath = window.location.pathname;
var virtualDirPath = virtualPath.substr(0, virtualPath.lastIndexOf('.')).substr(0, virtualPath.lastIndexOf('/')) || virtualPath;
// Add trailing slash if missing
virtualDirPath = virtualPath.substr(0, virtualPath.lastIndexOf('/')) + '/';
// console.log(hostPath);
// console.log(virtualPath);
// console.log(virtualDirPath);

//var baseURL = window.location.protocol + "//" + window.location.host + window.location.pathname + "/";

/* This function requires Jquery & Base64.encode */
function ConnectApi(_class, method, _arguments, callback, options) {
	if (typeof method !== 'string' || typeof _class !== 'string') {
		return false;
	}

	var data =
		{
			'method': method,
			'class': _class,
			'dataType': (typeof options !== 'undefined' && typeof options.dataType !== 'undefined' ? options.dataType : 'json')
		};

	if (typeof _arguments !== 'undefined') {
		data = $.extend(data, {arguments: _arguments});
	}

	var ajaxOptions =
		{
			type: "POST",
			url: "/ajax/ConnectApi.php",
			success: callback,
			error: function (jqXHR, textStatus) {
				//ignore logging aborts.
				if (textStatus !== 'abort') {
					console.log([data, ajaxOptions, arguments]);
				}
			},
			data: {
				call: Base64.encode(JSON.stringify(data))
			},
			dataType: data.dataType
		};

//	console.log(callback);

	if (typeof callback !== 'undefined') {
		ajaxOptions.callback = callback;
	}

	if (typeof options === 'object') {
		ajaxOptions = $.extend(ajaxOptions, options);
	}

//	console.log(data);
//	console.log(ajaxOptions);
//	console.log($ajaxObject);

	return $.ajax(ajaxOptions);
}
