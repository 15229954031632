var path = window.location.href; // because the 'href' property of the DOM element is the absolute path
$('ul.nav.navbar-nav a').each(function () {
	if (this.href === path) {
		$(this).parent().addClass('active');
	}
});

$("#settings-icon").click(function () {
	$("#settings-wrapper").toggle();
	$("#title-settings").toggle();
	$(".navbar-brand").toggle();
	$(".header-login.navbar-right").toggle();
	//$("#main-container").css({"margin-right":"2vw"});
	$("#main-container").toggleClass("move-right");
	$(".main-content>.row").toggleClass("move-right");
	$("#navbar>.nav.navbar-nav").toggleClass("navbar-right");
});

$("button.save-half-life").on("click", function (e) {
	e.preventDefault();
	var $button = $(this);
	var sendData = false;

	$('#settings input:text.half-life').each(function () {
		var $this = $(this);
		var inputFormatted = $this.val();
		var defaultFormatted = $this.data('default');

		inputFormatted = Number(inputFormatted.replace(/ /g, '').replace(',', '.'));
		defaultFormatted = Number(defaultFormatted);

		if (inputFormatted != defaultFormatted){
			if (!sendData) {
				sendData = {};
			}

			sendData[$this.data('channel_id')] = inputFormatted;

			$this.data('default', inputFormatted);
		}

		// Confirm user interaction

		$(this).addClass('flash');

		setTimeout(function () {
			$($this).removeClass('flash');
		}, 1000);

	});

	if (sendData){
		ConnectApi('SpendEditor', 'setHalfLife', [sendData], function (output) {
			ConnectApi('SpendEditor', 'runPotentials', [], function (x) {
				console.log(x);
			});
		});
	}
});

$("button.save-period").on("click", function (e) {
	e.preventDefault();
	var $button = $(this);
	var sendData = false;

	$('#settings input:radio.period').each(function () {
		var $this = $(this);

		if ($this.data('channel_id')) {
			var $new_period = $('input[name=period-' + $this.data('channel_id') + ']:checked').val();
			var default_data = $this.data('default');

			if ($new_period != default_data)
			{
				if (!sendData) {
					sendData = {};
				}

				sendData[$this.data('channel_id')] = $new_period;
				$this.data('default', $new_period);
			}
		}
	});

	if (sendData){
		ConnectApi('SpendEditor', 'setPeriod', [sendData], function (output) {
			console.log(output);
		});
	}

	$button.addClass('disabled');
	$("#save-something").fadeIn();
	$("#save-something .modal-header>h4").text("Period saved");
	$("#save-something .modal-body").text("Done!");
	$("#save-something .modal-content").css({"background-color": "#dff0d8"});
	setTimeout(function () {
		$button.removeClass('disabled');
		$("#save-something").fadeOut().toggle(); // remove modal so we can start over in a fresh state
	}, 800);
});

$("button.save-activity").on("click", function (e) {
	e.preventDefault();
	var $button = $(this);
	var sendData = false;

	$('#settings input:checkbox.active').each(function () {
		var $this = $(this);
		var $new_activity = $this.is(':checked') ? 1 : 0;
		var default_data = $this.data('default');

		if ($new_activity != default_data) {
			if (!sendData) {
				sendData = {};
			}

			sendData[$this.data('channel_id')] = $new_activity;
			$this.data('default', $new_activity);
		}
	});

	if (sendData) {
		ConnectApi('SpendEditor', 'setActivity', [sendData], function (output) {
			console.log(output);
		});
	}

	$button.addClass('disabled');
	$("#save-something").fadeIn();
	$("#save-something .modal-header>h4").text("Period saved");
	$("#save-something .modal-body").text("Done!");
	$("#save-something .modal-content").css({"background-color": "#dff0d8"});
	setTimeout(function () {
		$button.removeClass('disabled');
		$("#save-something").fadeOut().toggle(); // remove modal so we can start over in a fresh state
	}, 800);
});

$("button.save-invoiced").on("click", function (e) {
	e.preventDefault();
	var $button = $(this);
	var sendData = false;

	$('#settings input:checkbox.invoiced').each(function () {
		var $this = $(this);
		var $new_invoiced = $this.is(':checked') ? 1 : 0;
		var $default_data = $this.data('default');

		if ($new_invoiced != $default_data) {
			if (!sendData) {
				sendData = {};
			}

			sendData[$this.data('channel_id')] = $new_invoiced;
			$this.data('default', $new_invoiced);
		}
	});

	if (sendData) {
		ConnectApi('SpendEditor', 'setInvoiced', [sendData], function (output) {
			console.log(output);
		});
	}

	$button.addClass('disabled');
	$("#save-something").fadeIn();
	$("#save-something .modal-header>h4").text("Period saved");
	$("#save-something .modal-body").text("Done!");
	$("#save-something .modal-content").css({"background-color": "#dff0d8"});
	setTimeout(function () {
		$button.removeClass('disabled');
		$("#save-something").fadeOut().toggle(); // remove modal so we can start over in a fresh state
	}, 800);
});


$("button.save-order").on("click", function (e) {
	e.preventDefault();
	var $button = $(this);
	var sendData = false;
	var $new_order = 1;

	$('#settings input:text.half-life').each(function () {
		var $this = $(this);
		var default_data = $this.data('order-default');

		if ($new_order != default_data) {
			if (!sendData) {
				sendData = {};
			}
			sendData[$this.data('channel_id')] = $new_order;
			$this.data('order-default', $new_order);
		}
		$new_order = $new_order + 1;
	});

	if (sendData){
		ConnectApi('SpendEditor', 'setOrder', [sendData], function (output) {
			console.log(output);
		});
	}

	$button.addClass('disabled');
	$("#save-something").fadeIn();
	$("#save-something .modal-header>h4").text("Period saved");
	$("#save-something .modal-body").text("Done!");
	$("#save-something .modal-content").css({"background-color": "#dff0d8"});
	setTimeout(function () {
		$button.removeClass('disabled');
		$("#save-something").fadeOut().toggle();
	}, 800);
});


$("button#add-camp-submit").click(function () {
	var $button = $(this);
	var modal_inital_content_add_camp = $('#add-campaign-modal>.modal-dialog');

	// console.log(spendLevelModal);

	$button.addClass('disabled');
	// alert("The button was clicked.");

	var modal_running_content_add_camp = ('\
	<div class="modal-dialog">\
	<div class="modal-content style="background-color:#fff">\
	<div class="modal-header">\
	<button type="button" class="close" data-dismiss="modal" aria-hidden="true">x</button>\
	<h4>Add Campaign</h4>\
	</div>\
	<div class="modal-body text-center">Adding campaign...</div>\
	</div>\
	</div>\
	');

	var modal_complete_content_add_camp = ('\
	<div class="modal-dialog">\
	<div class="modal-content" style="background-color:#dff0d8">\
	<div class="modal-header">\
	<button type="button" class="close" data-dismiss="modal" aria-hidden="true">x</button>\
	<h4>Add Campaign</h4>\
	</div>\
	<div class="modal-body text-center"><a href="#" data-dismiss="modal" aria-hidden="true">Done!</a></div>\
	</div>\
	</div>\
	');

	// console.log($('#spndlvl').val());
	var $new_name = ($('#new-camp-name').val());
	var $new_group = ($('#new-camp-group').val());
	var $new_fun = ($('#new-camp-fun').val());
	var $new_dep = ($('#new-camp-dep').val());
	var $name = $new_name + ' ' + $new_group + ' ' + $new_fun

	$('#add-campaign-modal>.modal-dialog').replaceWith(modal_running_content_add_camp);
	ConnectApi('SpendEditor', 'addCampaign', [$name, $new_dep], function (output) {
		console.log(output);
		// $('#add-campaign-modal>.modal-dialog').replaceWith(modal_complete_content_add_camp);
		// $('#add-campaign-modal').fadeOut('slow');
		// $('#add-campaign-modal').modal('toggle'); // close modal
		setTimeout(function () {
			$("#add-campaign-modal").fadeOut().toggle();
			document.location.reload();
		}, 1500);
	});
});


$("button#conf-del-submit").click(function () {
	var $button = $(this);
	var modal_inital_content_spend_level = $('#del-campaign-modal>.modal-dialog');

	// console.log(spendLevelModal);

	$button.addClass('disabled');
	// alert("The button was clicked.");

	var modal_running_content_del_camp = ('\
	<div class="modal-dialog">\
	<div class="modal-content style="background-color:#fff">\
	<div class="modal-header">\
	<button type="button" class="close" data-dismiss="modal" aria-hidden="true">x</button>\
	<h4>Delete Campaign</h4>\
	</div>\
	<div class="modal-body text-center">Deleting campaign...</div>\
	</div>\
	</div>\
	');

	var modal_complete_content_del_camp = ('\
	<div class="modal-dialog">\
	<div class="modal-content" style="background-color:#dff0d8">\
	<div class="modal-header">\
	<button type="button" class="close" data-dismiss="modal" aria-hidden="true">x</button>\
	<h4>Delete Campaign</h4>\
	</div>\
	<div class="modal-body text-center"><a href="#" data-dismiss="modal" aria-hidden="true">Done!</a></div>\
	</div>\
	</div>\
	');

	// console.log($('#spndlvl').val());
	var $del_name = ($('#del-camp-opt').val());

	$('#confirm-del-modal').fadeOut('fast');
	$('#confirm-del-modal').modal('toggle');
	$('#del-campaign-modal>.modal-dialog').replaceWith(modal_running_content_del_camp);
	ConnectApi('SpendEditor', 'delCampaign', [$del_name], function (output) {
		console.log(output);
		// $('#del-campaign-modal>.modal-dialog').replaceWith(modal_complete_content_del_camp);
		// $('#del-campaign-modal').fadeOut('slow');
		// $('#del-campaign-modal').modal('toggle'); // close modal

		setTimeout(function () {
			$("#del-campaign-modal").fadeOut().toggle();
			document.location.reload();
		}, 1500);

	});
});


$(function () {
	$("#setting-body").sortable(
		{
			axis: "y",
			containment: "parent",
			tolerance: "pointer",
			opacity: 0.5,
			start: function (event, ui) {
				$(ui.item).addClass("drag-style");
			},
			stop: function (event, ui) {
				$(ui.item).removeClass("drag-style");
			}
		});
});
