/*jslint browser: true*/
/*global window, jQuery */

//var econometrics_store = {};
// $('input[type=text].store-this,select.store-this,textarea.store-this').each(function(){
// 	var key = $(this).attr('id');
// 	var value = $(this).val();
// 	localStorage.setItem(key, value)
// })

var t = '';
$('input[type=text].store-this,select.store-this,textarea.store-this').keyup(function () {
	clearTimeout(t);
	var key = $(this).attr('id');
	var value = $(this).val();
	t = setTimeout(function () {
		localStorage.setItem(key, value);
	}, 2000);
});

$('input[type=radio].store-this,input[type=checkbox].store-this').click(function () {
	//console.log(this);
	if ($(this).attr("checked", "checked")) {
		var key = $(this).attr('name');
		var value = $(this).val();
		console.log(this + ": " + key, value);
		localStorage.removeItem(key);
		localStorage.setItem(key, value);
		//localStorage.setItem("data", $(this).val());
		// } else {
		// 	var key = $(this).attr('name');
		// 	var value = $(this).val();
		// 	console.log(this, key, value + ' is NOT checked');
		// 	//localStorage.removeItem("data");
	}
});

$(function () {
	/*
	 * check if browser supports local storage
	 */
	if (localStorage) {
		//console.log("localStorage supported");
		/*
		 * if form field values exist in local storage use
		 * them to populate the form when the page loads
		 */
		if (typeof localStorage !== 'undefined' && localStorage.length > 0) {

			$('input[type=text].store-this,select.store-this,textarea.store-this').each(function () {
				var key = $(this).attr('id');
				var value = $(this).val();
				//console.log(localStorage.getItem(key));
				//console.log(key, value);
				if ((localStorage[key] !== value) && ($(this).val() !== localStorage.getItem(key))) {
					$(this).val(value);
					console.log(key + " has been set to " + value + "!");
				}
			});

			$('input[type=radio].store-this,input[type=checkbox].store-this').each(function () {
				var key = $(this).attr('name');
				var value = $(this).val();
				//console.log(localStorage.getItem(key));
				//console.log(key, value);
				if ((localStorage[key] === value) && ($(this).val() === localStorage.getItem(key))) {
					$(this).prop("checked", true);
					console.log(key + " has been set to " + value);
				}
			});

		}
	}

	// MORE DEBUG
	// for (var i = 0; i < localStorage.length; i++) {
	// 	$('body').append(localStorage.getItem(localStorage.key(i)));
	// }
});
